import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './app/layout/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './app/layout/MainLayout';

const Loadable = (Component) => (props) => (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const NewPassword = Loadable(lazy(() => import('./pages/authentication/NewPassword')));
const ResetPassword = Loadable(lazy(() => import('./pages/authentication/ResetPassword')));
const ConfirmEmail = Loadable(lazy(() => import('./pages/authentication/ConfirmEmail')));

// Dashboard pages
const HomeLayout = Loadable(lazy(() => import('./pages/viola/Home')))
const WorkersList = Loadable(lazy(() => import('./pages/viola/Departments/Workers')));
const UsersList = Loadable(lazy(() => import('./pages/viola/Users/Users/UsersScreen')));
const Permissions = Loadable(lazy(() => import('./pages/viola/Users/Permissions/PermissionsScreen')));
const Controls = Loadable(lazy(() => import('./pages/viola/Accountancy/Controls')));
const Transfers = Loadable(lazy(() => import('./pages/viola/Transfers/Transfers/TransfersScreen')));
const TransfersConfiguration = Loadable(lazy(() => import('./pages/viola/Transfers/Configuration/TransfersConfigurationScreen')));
const CashReport = Loadable(lazy(() => import('./pages/viola/Accountancy/CashReport')));
const Operations = Loadable(lazy(() => import('./pages/viola/Accountancy/Operations')));
const OpeningBalance = Loadable(lazy(() => import('./pages/viola/Accountancy/OpeningBalance')));
const InvoiceCorrections = Loadable(lazy(() => import('./pages/viola/Accountancy/InvoiceCorrections')));
const Revenues = Loadable(lazy(() => import('./pages/viola/Accountancy/Revenues')));
const ManualRevenues = Loadable(lazy(() => import('./pages/viola/Accountancy/ManualRevenues')));
const Complaints = Loadable(lazy(() => import('./pages/viola/Customer/Complaints')));
const ComplaintsPerformance = Loadable(lazy(() => import('./pages/viola/Customer/Complaints/PerformanceView')));
const ComplaintView = Loadable(lazy(() => import('./pages/viola/Customer/Complaints/ComplaintView')));
const Orders = Loadable(lazy(() => import('./pages/viola/Customer/Orders')));
const PartnerOrders = Loadable(lazy(() => import('./pages/viola/Customer/PartnerOrders')));
const OrdersPerformance = Loadable(lazy(() => import('./pages/viola/Customer/Orders/PerformanceView')));
const OrdersAfterBookingProduct = Loadable(lazy(() => import('./pages/viola/Customer/OrdersAfterBookingProduct')));
const DriversCash = Loadable(lazy(() => import('./pages/viola/Drivers/DriversCash')));
const DriversManagement = Loadable(lazy(() => import('./pages/viola/Drivers/DriversManagement')));
const ApprovalPayments = Loadable(lazy(() => import('./pages/viola/Accountancy/ApprovalPayments')));
const ApprovalPaymentsArchive = Loadable(lazy(() => import('./pages/viola/Accountancy/ApprovalPayments/Archive')));
const ApprovalPaymentsTerminal = Loadable(lazy(() => import('./pages/viola/Accountancy/ApprovalPaymentsTerminal')));
const ApprovalPaymentsTerminalArchive = Loadable(lazy(() => import('./pages/viola/Accountancy/ApprovalPaymentsTerminal/Archive')));
const ApprovalCashReports = Loadable(lazy(() => import('./pages/viola/Accountancy/ApprovalCashReports')));
const Vacations = Loadable(lazy(() => import('./pages/viola/Departments/Vacations')));
const Departments = Loadable(lazy(() => import('./pages/viola/Departments/Departments')));
const ShopAccounts = Loadable(lazy(() => import('./pages/viola/Shops/ShopAccounts/ShopAccountsScrean')));
const ShopTerminals = Loadable(lazy(() => import('./pages/viola/Shops/ShopTerminals/ShopTerminalsScreen')));
const ShopsManagement = Loadable(lazy(()=> import('./pages/viola/Shops/ShopsManagement/ShopsManagementScreen')));
const ShopView = Loadable(lazy(()=> import('./pages/viola/Shops/ShopsManagement/ShopView/ShopViewScreen')));
const StationaryShopsStatistics = Loadable(lazy(()=> import('./pages/viola/Shops/StationaryShopsStatistics/StationaryShopsStatisticsScreen')));
const ShopsMonitoring = Loadable(lazy(()=> import('./pages/viola/Shops/ShopsMonitoring/ShopsMonitoringScreen')));
const KiosksStatus = Loadable(lazy(()=> import('./pages/viola/Vkiosks/Status')));
const Notifications = Loadable(lazy(() => import('./pages/viola/News/Notifications/NotificationsScreen')));
const NotificationsGroups = Loadable(lazy(() => import('./pages/viola/News/NotificationsGroups/NotificationsGroupScreen')));
const Products = Loadable(lazy(() => import('./pages/viola/Warehouse/Products')));
const ProductsExcluded = Loadable(lazy(() => import('./pages/viola/Warehouse/ProductsExcluded')));
const ProductCategories = Loadable(lazy(() => import('./pages/viola/Warehouse/ProductCategories')));
const ProductsReplenishment = Loadable(lazy(() => import('./pages/viola/Warehouse/ProductsReplenishment')));
const ProductDetails = Loadable(lazy(() => import('./pages/viola/Warehouse/ProductDetails')));
const ProductsDistribution = Loadable(lazy(() => import('./pages/viola/Warehouse/ProductsDistribution')));
const Attributes = Loadable(lazy(() => import('./pages/viola/Warehouse/ProductCategories/Attributes')));
const Shipment = Loadable(lazy(() => import('./pages/viola/Warehouse/Shipment')));
const ExternalShipment = Loadable(lazy(() => import('./pages/viola/Warehouse/ExternalShipment/ExternalShipmentScreen')));
const OrderViewList = Loadable(lazy(() => import('./pages/viola/Customer/Orders/OrderViewList')));
const PartnerOrderViewList = Loadable(lazy(() => import('./pages/viola/Customer/PartnerOrders/PartnerOrderViewList')));
const Inventory = Loadable(lazy(() => import('./pages/viola/Warehouse/Inventory')));
const RemovingReservation = Loadable(lazy(() => import('./pages/viola/Warehouse/RemovingReservation')));
const Printers = Loadable(lazy(() => import('./pages/viola/Warehouse/Printers/PrintersScreen')));
const Sectors = Loadable(lazy(() => import('./pages/viola/Warehouse/Sectors')));
const Sorter = Loadable(lazy(() => import('./pages/viola/Warehouse/Sorter')));
const SorterProducts = Loadable(lazy(() => import('./pages/viola/Warehouse/SorterProducts')));
const SorterConfiguration = Loadable(lazy(() => import('./pages/viola/Warehouse/SorterConfiguration')));
const ProductFinder = Loadable(lazy(() => import('./pages/viola/Warehouse/ProductFinder')));
const ShipmentList = Loadable(lazy(() => import('./pages/viola/Warehouse/ShipmentList')));
const CompanySellingReport = Loadable(lazy(() => import('./pages/viola/Accountancy/CompanySellingReport')));
const ShipmentBoxManagement = Loadable(lazy(() => import('./pages/viola/Warehouse/ShipmentBoxManagement')));
const CompanyWarehouseReport = Loadable(lazy(() => import('./pages/viola/Accountancy/CompanyWarehouseReport')));
const ShipmentListViolaPL = Loadable(lazy(() => import('./pages/viola/WebshopViolaPL/ShipmentList')));
const WebshopPerformanceView = Loadable(lazy(() => import('./pages/viola/WebshopViolaPL/PerformanceView')));
const SalesReportViolaPL = Loadable(lazy(() => import('./pages/viola/WebshopViolaPL/SalesReport')))
const WarehouseOrders = Loadable(lazy(() => import('./pages/viola/Warehouse/WarehouseOrders')))

// Error pages
const PageNotReady = Loadable(lazy(() => import('./pages/PageNotReady')));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
        )
      },
      {
        path: 'new-password',
        element: (
            <GuestGuard>
              <NewPassword />
            </GuestGuard>
        )
      },
      {
        path: 'reset-password',
        element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
        )
      },
      {
        path: 'confirm-email',
        element: (
            <GuestGuard>
              <ConfirmEmail />
            </GuestGuard>
        )
      },
    ]
  },
  // dashboard
  {
    path: '/dashboard',
    element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <HomeLayout variant="Home"/>
      },
      {
        path: '/cash-report',
        element: <CashReport />
      },
      {
        path: '/opening-balance',
        element: <OpeningBalance />
      },
      {
        path: '/approval-payments',
        element: <ApprovalPayments />
      },
      {
        path: '/approval-payments/archive',
        element: <ApprovalPaymentsArchive />
      },
      {
        path: '/approval-payments-terminal',
        element: <ApprovalPaymentsTerminal />
      },
      {
        path: '/approval-payments-terminal/archive',
        element: <ApprovalPaymentsTerminalArchive />
      },
      {
        path: '/approval-cash-reports',
        element: <ApprovalCashReports />
      },
      {
        path: '/operations',
        element: <Operations />
      },
      {
        path: '/revenues',
        element: <Revenues />
      },
      {
        path: '/manual-revenues',
        element: <ManualRevenues />
      },
      {
        path: '/shop-accounts',
        element: <ShopAccounts />
      },
      {
        path: '/shop-terminals',
        element: <ShopTerminals />
      },
      {
        path: '/controls',
        element: <Controls />
      },
      {
        path: '/invoice-corrections',
        element: <InvoiceCorrections />
      },
      {
        path: '/transfers',
        element: <Transfers />
      },
      {
        path: '/transfers-configuration',
        element: <TransfersConfiguration />,
      },
      {
        path: '/drivers-cash',
        element: <DriversCash/>
      },
      {
        path: '/drivers-list',
        element: <DriversManagement/>
      },
      {
        path: '/shops-management',
        element: <ShopsManagement />
      },
      {
        path: '/shop-view',
        element: <ShopView />
      },
      {
        path: '/shops-monitoring',
        element: <ShopsMonitoring />
      },
      {
        path: '/stationary-shops-statistics',
        element: <StationaryShopsStatistics />
      },
      {
        path: '/notifications',
        element: <Notifications />
      },
      {
        path: '/notifications-groups',
        element: <NotificationsGroups />
      },
      {
        path: 'complaints',
        element: <Complaints />
      },
      {
        path: 'complaint-view',
        element: <ComplaintView />
      },
      {
        path: 'complaints/performance',
        element: <ComplaintsPerformance />
      },
      {
        path: 'orders',
        element: <Orders />
      },
      {
        path: 'orders-after-booking-product',
        element: <OrdersAfterBookingProduct />
      },
      {
        path: 'orders/performance',
        element: <OrdersPerformance />
      },
      {
        path: 'orders-view-list',
        element: <OrderViewList />
      },
      {
        path: 'partner-orders',
        element: <PartnerOrders />
      },
      {
        path: 'partner-orders-view-list',
        element: <PartnerOrderViewList />
      },
      {
        path: 'v-kiosks-status',
        element: <KiosksStatus />
      },
      {
        path: 'users-list',
        element: <UsersList />
      },
      {
        path: 'workers-list',
        element: <WorkersList />
      },
      {
        path: 'permissions',
        element: <Permissions />
      },
      {
        path: 'products',
        element: <Products />
      },
      {
        path: 'products-replenishment',
        element: <ProductsReplenishment />
      },
      {
        path: 'products-excluded',
        element: <ProductsExcluded />
      },
      {
        path: 'products-distribution',
        element: <ProductsDistribution />
      },
      {
        path: 'product-categories',
        element: <ProductCategories />
      },
      {
        path: 'categories-attributes',
        element: <Attributes />
      },
      {
        path: 'product-details',
        element: <ProductDetails />
      },
      {
        path: 'shipment',
        element: <Shipment />
      },
      {
        path: 'external-shipment',
        element: <ExternalShipment />
      },
      {
        path: 'inventory',
        element: <Inventory />
      },
      {
        path: 'removing-reservation',
        element: <RemovingReservation />
      },
      {
        path: 'sectors',
        element: <Sectors />
      },
      {
        path: 'departments',
        element: <Departments />
      },
      {
        path: 'vacations',
        element: <Vacations />
      },
      {
        path: 'account',
        element: <PageNotReady/>
      },
      {
        path: 'printers',
        element: <Printers />
      },
      {
        path: 'sorter',
        element: <Sorter />
      },
      {
        path: 'sorter-products',
        element: <SorterProducts />
      },
      {
        path: 'sorter-configuration',
        element: <SorterConfiguration />
      },
      {
        path: 'product-finder',
        element: <ProductFinder />
      },
      {
        path: 'company-selling-report',
        element: <CompanySellingReport />
      },
      {
        path: 'shipment-list',
        element: <ShipmentList />
      },
      {
        path: 'shipment-list-viola-pl',
        element: <ShipmentListViolaPL />
      },
      {
        path: 'performance-viola-pl',
        element: <WebshopPerformanceView />
      },
      {
        path: 'sales-report-viola-pl',
        element: <SalesReportViolaPL />
      },
      {
        path: 'shipment-box-management',
        element: <ShipmentBoxManagement />
      },
      { path: 'company-warehouse-report',
        element: <CompanyWarehouseReport />
      },
      { path: 'warehouse-orders',
        element: <WarehouseOrders />
      }
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" />
      },
    ]
  }
];

export default routes;
